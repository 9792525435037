<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="loading">
            <b-row>
                <b-col lg="12" sm="12">
                    <CCard>
                        <CCardHeader>
                            <div class="row">
                                <div class="col-md-6">
                                    <strong>App Update Setting</strong>
                                </div>
                            </div>
                        </CCardHeader>
                        <CCardBody>
                            <div class='row'>
                                <b-col lg="12" sm="12">
                                    
                                        <ValidationObserver ref="form1"  v-slot="{ handleSubmit, reset }">
                                            <b-form  @submit.prevent="handleSubmit(register(update))" @reset.prevent="reset" >
                                                <div>
                                                    <p class="m-0 mb-2" style="font-weight: bold"> Maintance Mode </p>
                                                    <b-form-checkbox v-model="update.maintance" size="md" name="check-button" switch>
                                                        <span v-if="update.maintance">ON</span>
                                                        <span v-else>OF</span>
                                                    </b-form-checkbox>
                                                </div>
                                                <div>
                                                    <p class="m-0 mb-2" style="font-weight: bold">  Task ON OFF  </p>
                                                    <b-form-checkbox v-model="update.task_on_off" size="md" name="check-button" switch>
                                                        <span v-if="update.task_on_off">ON</span>
                                                        <span v-else>OF</span>
                                                    </b-form-checkbox>
                                                </div>
                                                <div>
                                                    <p class="m-0 mb-2" style="font-weight: bold">  Refer ON OF   </p>
                                                    <b-form-checkbox v-model="update.refer_on_of" size="md" name="check-button" switch>
                                                        <span v-if="update.refer_on_of">ON</span>
                                                        <span v-else>OF</span>
                                                    </b-form-checkbox>
                                                </div>
                                                <div class='row'>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Version" vid="version" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Current App Version
                                                            </template>
                                                            <b-form-input
                                                                type="text"
                                                                id="version"
                                                                v-model="update.version"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Version" vid="dlink" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            App Download link
                                                            </template>
                                                            <b-form-input
                                                                id="dlink"
                                                                v-model="update.dlink"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                </div>
                                                <div class="text-right">
                                                    <b-button type="submit" variant="primary" style="margin-top:40px" class="mr-2">Update</b-button>
                                                </div>
                                            </b-form>
                                        </ValidationObserver>
                                </b-col>                                
                            </div>
                        </CCardBody>
                    </CCard>
                </b-col>
                <b-col lg="12" sm="12">
                    <CCard>
                        <CCardHeader>
                            <div class="row">
                                <div class="col-md-6">
                                    <strong>App Setting's</strong>
                                </div>
                            </div>
                        </CCardHeader>
                        <CCardBody>
                            <div class='row'>
                                <b-col lg="12" sm="12">
                                    
                                        <ValidationObserver ref="form2"  v-slot="{ handleSubmit, reset }">
                                            <b-form  @submit.prevent="handleSubmit(register(setting))" @reset.prevent="reset" >
                                                <div class='row'>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Main Notice Box" vid="notice" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Main Notice Box
                                                            </template>
                                                            <b-form-textarea
                                                                type="number"
                                                                id="notice"
                                                                v-model="setting.notice"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Dollar Conv Notice Box" vid="convert_notice" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Dollar Conv Notice Box
                                                            </template>
                                                            <b-form-textarea
                                                                type="number"
                                                                id="convert_notice"
                                                                v-model="setting.convert_notice"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Payment Notice Box" vid="payment_notich" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Payment Notice Box
                                                            </template>
                                                            <b-form-textarea
                                                                type="number"
                                                                id="payment_notich"
                                                                v-model="setting.payment_notich"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Refer Notice" vid="refer_notice" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                                Refer Notice
                                                            </template>
                                                            <b-form-textarea
                                                                type="number"
                                                                id="refer_notice"
                                                                v-model="setting.refer_notice"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                </div>
                                                <div class="text-right">
                                                    <b-button type="submit" variant="primary" style="margin-top:40px" class="mr-2">Update</b-button>
                                                </div>
                                            </b-form>
                                        </ValidationObserver>
                                    
                                </b-col>                                
                            </div>
                        </CCardBody>
                    </CCard>
                </b-col>
                <b-col lg="12" sm="12">
                    <CCard>
                        <CCardHeader>
                            <div class="row">
                                <div class="col-md-6">
                                    <strong>App Ads Unit Setting's</strong>
                                </div>
                            </div>
                        </CCardHeader>
                        <CCardBody>
                            <div class='row'>
                                <b-col lg="12" sm="12">
                                    
                                        <ValidationObserver ref="form3"  v-slot="{ handleSubmit, reset }">
                                            <b-form  @submit.prevent="handleSubmit(register(addUnit))" @reset.prevent="reset" >
                                                <div class='row'>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Strapps Apps ID" vid="strapps" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Strapps Apps ID
                                                            </template>
                                                            <b-form-input
                                                                id="strapps"
                                                                v-model="addUnit.strapps"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Unity game ID" vid="unity" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Unity game ID
                                                            </template>
                                                            <b-form-input
                                                                id="unity"
                                                                v-model="addUnit.unity"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                </div>
                                                <div class="text-right">
                                                    <b-button type="submit" variant="primary" style="margin-top:40px" class="mr-2">Update</b-button>
                                                </div>
                                            </b-form>
                                        </ValidationObserver>
                                    
                                </b-col>                                
                            </div>
                        </CCardBody>
                    </CCard>
                </b-col>
                <b-col lg="12" sm="12">
                    <CCard>
                        <CCardHeader>
                            <div class="row">
                                <div class="col-md-6">
                                    <strong> App link Setting's</strong>
                                </div>
                            </div>
                        </CCardHeader>
                        <CCardBody>
                            <div class='row'>
                                <b-col lg="12" sm="12">
                                    
                                        <ValidationObserver ref="form4"  v-slot="{ handleSubmit, reset }">
                                            <b-form  @submit.prevent="handleSubmit(register(link))" @reset.prevent="reset" >
                                                <div class='row'>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="How Work link" vid="howwork" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            How Work link
                                                            </template>
                                                            <b-form-input
                                                                id="howwork"
                                                                v-model="link.howwork"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Telegram Group Link" vid="telegram" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Telegram Group Link
                                                            </template>
                                                            <b-form-input
                                                                id="telegram"
                                                                v-model="link.telegram"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Facebook Page Link" vid="fb_Link" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Facebook Page Link
                                                            </template>
                                                            <b-form-input
                                                                id="fb_Link"
                                                                v-model="link.fb_Link"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                </div>
                                                <div class="text-right">
                                                    <b-button type="submit" variant="primary" style="margin-top:40px" class="mr-2">Update</b-button>
                                                </div>
                                            </b-form>
                                        </ValidationObserver>
                                    
                                </b-col>                                
                            </div>
                        </CCardBody>
                    </CCard>
                </b-col>
                <b-col lg="12" sm="12">
                    <CCard>
                        <CCardHeader>
                            <div class="row">
                                <div class="col-md-6">
                                    <strong> App 10 link Setting's</strong>
                                </div>
                            </div>
                        </CCardHeader>
                        <CCardBody>
                            <div class='row'>
                                <b-col lg="12" sm="12">
                                        <ValidationObserver ref="form6"  v-slot="{ handleSubmit, reset }">
                                            <b-form  @submit.prevent="handleSubmit(register(link))" @reset.prevent="reset" >
                                                <div class='row'>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 1" vid="link_1" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 1
                                                            </template>
                                                            <b-form-input
                                                                id="link_1"
                                                                v-model="link.link_1"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 2" vid="link_2" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 2
                                                            </template>
                                                            <b-form-input
                                                                id="link_2"
                                                                v-model="link.link_2"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 3" vid="link_3" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 3
                                                            </template>
                                                            <b-form-input
                                                                id="link_3"
                                                                v-model="link.link_3"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 4" vid="link_4" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 4
                                                            </template>
                                                            <b-form-input
                                                                id="link_4"
                                                                v-model="link.link_4"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 5" vid="link_5" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 5
                                                            </template>
                                                            <b-form-input
                                                                id="link_5"
                                                                v-model="link.link_5"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 6" vid="link_6" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 6
                                                            </template>
                                                            <b-form-input
                                                                id="link_6"
                                                                v-model="link.link_6"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 7" vid="link_7" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 7
                                                            </template>
                                                            <b-form-input
                                                                id="link_7"
                                                                v-model="link.link_7"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 8" vid="link_8" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 8
                                                            </template>
                                                            <b-form-input
                                                                id="link_8"
                                                                v-model="link.link_8"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 9" vid="link_9" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 9
                                                            </template>
                                                            <b-form-input
                                                                id="link_9"
                                                                v-model="link.link_9"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 10" vid="link_10" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 10
                                                            </template>
                                                            <b-form-input
                                                                id="link_10"
                                                                v-model="link.link_10"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                </div>
                                                <div class="text-right">
                                                    <b-button type="submit" variant="primary" style="margin-top:40px" class="mr-2">Update</b-button>
                                                </div>
                                            </b-form>
                                        </ValidationObserver>
                                    
                                </b-col>                                
                            </div>
                        </CCardBody>
                    </CCard>
                </b-col>
                <b-col lg="12" sm="12">
                    <CCard>
                        <CCardHeader>
                            <div class="row">
                                <div class="col-md-6">
                                    <strong>Apps About Setting</strong>
                                </div>
                            </div>
                        </CCardHeader>
                        <CCardBody>
                            <div class='row'>
                                <b-col lg="12" sm="12">
                                    
                                        <ValidationObserver ref="form5"  v-slot="{ handleSubmit, reset }">
                                            <b-form  @submit.prevent="handleSubmit(register(about))" @reset.prevent="reset" >
                                                <div class='row'>
                                                    <b-col lg="12" sm="12">
                                                        <ValidationProvider name="About Us" vid="aboutus" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            About Us
                                                            </template>
                                                            <b-form-textarea
                                                                id="aboutus"
                                                                v-model="about.aboutus"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                </div>
                                                <div class="text-right">
                                                    <b-button type="submit" variant="primary" style="margin-top:40px" class="mr-2">Update</b-button>
                                                </div>
                                            </b-form>
                                        </ValidationObserver>
                                    
                                </b-col>                                
                            </div>
                        </CCardBody>
                    </CCard>
                </b-col>
            </b-row>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { baseUrl } from '../../config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import iziToast from 'izitoast';

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
	this.getData()
  },
  data () {
    return {
      update: {
        maintance: false,
        task_on_off: false,
        version: '',
        dlink: ''
      },
      setting: {
        notice: '',
        convert_notice: '',
        payment_notich: ''
      },
      addUnit: {
        strapps: '',
        unity: ''
      },
      link: {
        howwork: '',
        telegram: '',
        fb_Link: ''
      },
      about: {
        aboutus: '',
      },
      editId: ''
    }
  },
  computed: {
	loading () {
		return this.$store.state.static.loading
	}
  },
  methods: {
    getData () {
        this.$store.dispatch('mutedLoad', { loading: true})
        RestApi.getData(baseUrl, 'api/app-update/show').then(response => {
            if (response.success) {
                this.update = {
                    maintance: parseInt(response.data.maintance) === 1 ? true : false,
                    refer_on_of: parseInt(response.data.refer_on_of) === 1 ? true : false,
                    task_on_off: parseInt(response.data.task_on_off) === 1 ? true : false,
                    version: response.data.version,
                    dlink: response.data.dlink
                }
                this.setting = {
                    notice: response.data.notice,
                    convert_notice: response.data.convert_notice,
                    payment_notich: response.data.payment_notich,
                    refer_notice: response.data.refer_notice

                }
                this.addUnit = {
                    strapps: response.data.strapps,
                    unity: response.data.unity
                }
                this.link = {
                    howwork: response.data.howwork,
                    telegram: response.data.telegram,
                    fb_Link: response.data.fb_Link,
                    link_1: response.data.link_1,
                    link_2: response.data.link_2,
                    link_3: response.data.link_3,
                    link_4: response.data.link_4,
                    link_5: response.data.link_5,
                    link_6: response.data.link_6,
                    link_7: response.data.link_7,
                    link_8: response.data.link_8,
                    link_9: response.data.link_9,
                    link_10: response.data.link_10,
                    refer_notice: response.data.refer_notice
                }
                this.about = {
                    aboutus: response.data.aboutus
                }
            }
            this.$store.dispatch('mutedLoad', { loading: false })
        })
    },
    async register (Obj) {
        this.$store.dispatch('mutedLoad', { loading: true })
        let result = null
        result = await RestApi.postData(baseUrl, `api/app-update/update`, Obj)
        this.$store.dispatch('mutedLoad', { loading: false })
        if (result.success) {
			iziToast.success({
				title: 'Success',
				message: result.message
			})
        } else {
            this.$refs.form.setErrors(result.errors)
        }
    }
  }
}
</script>
